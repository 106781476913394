import leaflet, {LatLngExpression, Polygon} from "leaflet";

export const building: Polygon = leaflet.polygon([
    [51.6529023, 8.1522242],
    [51.6527896, 8.1522011],
    [51.6527671, 8.1524619],
    [51.6528423, 8.1524796],
    [51.6528585, 8.1522959],
    [51.6528946, 8.1523036],
    [51.6529023, 8.1522242]
],{
    color: 'green'
});

export const defaultCoordinates: LatLngExpression = [51.65272,8.15216];

export const copyright = leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
})

export const defaultIcon = leaflet.icon({
    iconUrl: new URL('./images/icon-map.png?width=32', import.meta.url).toString(),
    iconSize: [32,32]
})

export const homeIcon = leaflet.icon({
    iconUrl: new URL('./images/icon-home.png?width=32', import.meta.url).toString(),
    iconRetinaUrl: new URL('./images/icon-home.png?width=64', import.meta.url).toString(),
    iconSize: [32, 32]
})